<template>
<div class="app-container with-content-offset-bottom pt-0">
  <RosterNav :loading="state == 'loading'" :failure="state == 'failure'" :backgroundLoading="profileLoaderIntervalId > 0" />
  <div v-if="state == 'ready'">
    <div class="toolbar">
      <v-btn-toggle v-model="sortType" @change="sort" class="pr-4" group mandatory>
        <v-btn title="Сортувати за абеткою"><v-icon>mdi-alphabetical</v-icon></v-btn>
        <v-btn title="Групувати за часом останньої активності"><v-icon>mdi-clock-outline</v-icon></v-btn>
        <v-btn title="Групувати за класом"><v-icon>mdi-wizard-hat</v-icon></v-btn>
        <v-btn title="Групувати за расою"><v-icon>mdi-panda</v-icon></v-btn>
        <v-btn title="Групувати за рангом"><v-icon>mdi-police-badge-outline</v-icon></v-btn>
      </v-btn-toggle>
      <v-text-field v-model="filterText"
        :hint="`${displayMembers.length == members.length ? members.length : displayMembers.length + ' / ' + members.length} персонажів максимального рівня`"
        autofocus clearable outlined persistent-hint label="Пошук"></v-text-field>
    </div>
    <RosterList v-if="sortType == 0" class="mt-6" :members="displayMembers" :classes="classes" :specs="specs" clickable responsive @click-member="clickMember" />
    <div v-else>
      <div v-for="(g, i) in displayGroups" :key="i">
        <div v-if="getGroupMembers(g.id).length > 0">
          <h2 class="group-header">{{ g.name.toUpperCase() }}</h2>
          <RosterList :members="getGroupMembers(g.id)" :classes="classes" :specs="specs" clickable responsive @click-member="clickMember" />
        </div>
      </div>
    </div>
    <Member v-model="popupMemberModel" :member="popupMember" />
  </div>
</div>
</template>

<script>

import { apiFetch } from '@/utils'
import Member from '@/components/Member.vue'
import RosterNav from '@/components/RosterNav'
import RosterList from '@/components/RosterList'

export default {
  components: { Member, RosterNav, RosterList },
  data() {
    return {
      state: '?', // 'loading', 'failure', 'ready'
      races: [],
      classes: [],
      specs: [],
      members: [],
      profileLoaderIntervalId: 0,
      filterText: '',
      sortType: 0,
      popupMemberModel: false,
      popupMember: {},
    }
  },
  created: function () {
    this.setPageMetadata('Ростер')
    this.state = 'loading'
    Promise.all([
      apiFetch('/races'),
      apiFetch('/classes'),
      apiFetch('/specs'),
      apiFetch('/roster'),
    ]).then(([ races, classes, specs, members ]) => {
      this.races = races.sort((a, b) => a.loc.mul.localeCompare(b.loc.mul))
      this.classes = classes.sort((a, b) => a.loc.mul.localeCompare(b.loc.mul))
      this.specs = specs
      this.members = members
      this.sort(this.sortType)
      this.profileLoaderIntervalId = setInterval(this.profileLoader, 1000)
      this.state = 'ready'
    }).catch(() => this.state = 'failure')
  },
  destroyed: function () {
    this.stopProfileLoader()
  },
  computed: {
    displayGroups: function () {
      if (this.sortType == 1) {
        // keep in sync with getLoginGroup()
        return [
          { id: 'hours', name: 'Протягом доби' },
          { id: 'days', name: 'Більше доби' },
          { id: 'weeks', name: 'Більше тижня' },
          { id: 'months', name: 'Більше місяця' },
          { id: 'years', name: 'Більше року' },
        ]
      }

      if (this.sortType == 2) {
        return this.classes.map(e => ({ id: e.id, name: e.loc.mul }))
      }

      if (this.sortType == 3) {
        return this.races.map(e => ({ id: e.id, name: e.loc.mul }))
      }

      if (this.sortType == 4) {
        return [
          { id: 1, name: 'офіцери' },
          { id: 2, name: 'ветерани' },
          { id: 3, name: 'гладіатори' },
          { id: 4, name: 'юніори' },
          { id: 5, name: 'альти' },
          { id: 6, name: 'новачки' }
        ]
      }

      throw Error('sortType is not supported')
    },
    displayMembers: function () {
      const s = (this.filterText || '').toLowerCase().trim()
      return s
        ? this.members.filter(e => e.name.toLowerCase().indexOf(s) >= 0)
        : this.members
    }
  },
  methods: {
    profileLoader: function () {
      const stillLoading = this.members.filter(e => e.loadingStatus == 'loading').length
      if (stillLoading > 10) {
        return
      }

      let started = 0

      for (let i = 0; i < this.members.length; i++) {
        if (!this.members[i].loadingStatus) {
          this.members[i].loadingStatus = 'loading'
          const name = this.members[i].name
          const realm = this.members[i].realm
          const slug = `${name}-${realm}`.toLowerCase()

          apiFetch('/char/' + slug).then(data => {
            const m = this.members.find(e => e.name == name && e.realm == realm)
            m.loadingStatus = 'done'

            if (data.error) {
              m.login = 0
            } else {
              m.avatar = data.avatar
              m.inset = data.inset
              m.spec = data.spec
              m.login = data.login
            }

            m.loginGroup = this.getLoginGroup(m.login)

            this.sort(this.sortType)
          })

          if (++started > 20) {
            break;
          }
        }
      }

      if (started == 0) {
        this.stopProfileLoader()
      }
    },
    stopProfileLoader: function () {
      if (this.profileLoaderIntervalId > 0) {
        clearInterval(this.profileLoaderIntervalId)
        this.profileLoaderIntervalId = 0
      }
    },
    sort: function (type) {
      this.members.sort((a, b) => {
        if (type == 1) {
          return (b.login - a.login) || a.name.localeCompare(b.name)
        } else {
          return a.name.localeCompare(b.name)
        }
      })
    },
    getGroupMembers: function (group) {
      return this.displayMembers.filter(e => {
        if (this.sortType == 1) { return e.loginGroup == group }
        if (this.sortType == 2) { return e.class == group }
        if (this.sortType == 3) { return e.race == group }
        if (this.sortType == 4) { return e.rank == group }
      })
    },
    getLoginGroup: function (lastLoginTimestamp) {
      const now = Date.now()
      const diff = now - lastLoginTimestamp
      if (diff <= 24*60*60*1000) { return 'hours' }
      if (diff <= 7*24*60*60*1000) { return 'days' }
      if (diff <= 30*24*60*60*1000) { return 'weeks' }
      if (diff <= 365*24*60*60*1000) { return 'months' }
      return 'years'
    },
    clickMember: function (member) {
      this.popupMember = member
      this.popupMemberModel = true
      this.$gtag.event('roster_member_click', { member: member.name })
    }
  }
}

</script>

<style scoped>

.toolbar {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.group-header {
  margin: 1.25em 0;
  font-size: 150%;
  text-align: center;
}

@media (min-width: 720px) {
  .toolbar {
    width: 60%;
  }
}

@media (min-width: 1000px) {
  .toolbar {
    flex-direction: row;
  }
  .group-header {
    font-size: 200%;
  }
}

</style>
